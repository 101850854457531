<template>
  <section>
    <nav-menu></nav-menu>
    <div class="page-content-lightestblue">
      <div class="page-content-lightestblue__container">
        <h1>{{ t("LBL_NOT_FOUND_TITLE") }}</h1>
        <h1>{{ t("404") }}</h1>
        <p>{{ t("LBL_NOT_FOUND_TEXT") }}</p>

        <app-image :alt="error-404" :src="require('../assets/img/error-404.png')"></app-image>
      </div>
    </div>
  </section>
</template>

<script>
import NavMenu from "@/components/Menu/NavMenu";
import AppImage from "@/components/Image/Image";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "NotFound",
  components: {
    NavMenu,
    AppImage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const goToLandingPage = async () => {
      store.dispatch("catalog/setFilters", {});
    };
    return {
      goToLandingPage,
      t,
    };
  },
};
</script>
<style lang="postcss" scoped>
.page-content-lightestblue {
  background-color: #e5ecf6;
  &__container {
    @apply flex flex-col items-center text-center py-20;
    h1 {
      @apply text-center text-blue;
    }
    p {
      @apply text-center;
    }
  }
}
</style>
